.schedule {
  margin: 0 auto;
  max-width: $container-width;
  text-align: center;

  &__body {
    padding: 15px 0 40px;
  }

  &__recap {
    background: $grey;
    padding: 20px;

    &__teams {
      background: $white;
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
      padding: 10px 0;
      width: 100%;
    }

    &__team {
      align-items: center;
      display: flex;
      font-family: 'SourceSansPro-Bold';
      font-size: 20px;
      font-weight: bold;
      justify-content: space-around;
      width: 40%;

      .caret {
        font-size: 24px;
        margin-bottom: -6px;
      }

      &.maroon {
        color: $maroon;
      }

      img {
        display: block;
        width: 25px;
      }

      span {
        display: block;
      }
    }

    table {
      background: $white;
      box-shadow: 5px 5px 5px darken($grey, 20%);
      border-spacing: 0;
      margin: 0 auto;
      padding: 0 10px 20px;
      width: 100%;

      tr {
        &:nth-child(even) {
          background: $grey;
        }
      }

      td {
        padding: 6px;

        &.left {
          color: $maroon;
          text-align: right;
        }

        &.mid {
          color: $gold;
        }

        &.right {
          text-align: left;
        }
      }
    }
  }

  &__copy {
    padding: 0 20px 30px;

    h1 {
      color: $maroon;
      font-family: 'Pride';
      text-transform: uppercase;
    }

    h3 {
      color: $gold;
      font-family: 'SourceSansPro-Regular';
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &__meet {
    align-items: center;
    border-bottom: 2px solid $gold;
    display: grid;
    grid-template-columns: 20% 80%;
    justify-content: space-between;
    margin: 10px 20px;
    padding-bottom: 10px;
  }

  &__game {
    align-items: center;
    border-bottom: 2px solid $gold;
    display: grid;
    grid-template-columns: 20% 30% 10% 40%;
    justify-content: space-between;
    margin: 10px 20px;
    padding-bottom: 10px;

    &__date {
      font-family: 'SourceSansPro-Bold';

      a {
        border-bottom: 1px solid $maroon;
      }
    }

    &__team {
      font-family: 'SourceSansPro-Bold';
      font-size: 16px;
      padding: 5px 20px;
      text-align: center;
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .schedule {
    max-width: 100%;
  }
}

@media (max-width: 420px) {
  .schedule {
    &__recap {
      &__team {
        font-size: 16px;
      } 
    }

    &__copy {
        h1 {
          font-size: 22px;
        }

        h3 {
          font-size: 16px;
        }
      }

    &__game {
      &__date,
      &__team {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 380px) {
  .schedule {
    &__game {
      &__date,
      &__team {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 345px) {
  .schedule {
    &__recap {
      &__team {
        font-size: 14px;
      }

      table {
        font-size: 16px;
      }
    }
  }
}

.poll {
  margin: 0 auto;
  max-width: $container-width;
  text-align: center;

  &__question {
    font-size: 36px;
    font-weight: normal;
    margin: 20px 2em;
  }

  &__teams {
    margin-top: 20px;
    width: 200px;
  }

  .answer {
    margin: 1em 2em;

    p {
      color: $gold;
      font-size: 20px;
      font-weight: bold;
      margin: 30px auto;
    }

    &__buttons {
      width: 100%;
    }

    &__button {
      background: lighten($black, 85%);
      border: 0;
      border-radius: 45px;
      color: lighten($black, 30%);
      cursor: pointer;
      display: block;
      font-family: 'SourceSansPro-Bold';
      font-size: 26px;
      height: 50px;
      margin: 0 auto 15px;
      padding: 15px;
      position: relative;
      // text-transform: uppercase;
      transition: all .2s;
      width: 95%;

      &--fill {
        background: lighten($gold, 20%);
        border-radius: 45px 0 0 45px;
        bottom: 0;
        content: '\A';
        left: 0;
        position: absolute;
        top: 0;
        transition: width 1000ms ease-in-out;
        width: 0%;
      }

      &__interior {
        margin-top: -7px;
        position: relative;
        z-index: 2;

        &--result {
          display: flex;
          justify-content: space-between;

          div {
            margin: 0 20px;
          }
        }
      }
    }

    &__share {
      margin: 40px 0;
      width: 97%;
    }

    &__social {
      display: flex;
      justify-content: space-around;
      margin-bottom: 40px;

      &__button {
        background: $blue;
        border-radius: 35px;
        color: $white;
        cursor: pointer;
        font-size: 20px;
        padding: 15px;
        width: 200px;

        &--twitter {
          background: $twitter-blue;
        }

        &--facebook {
          background: $facebook-blue;
        }
      }
    }
  }

  &__thanks {
    margin: 1em 2em;

    h2 {
      font-size: 32px;
      font-weight: bold;
      margin-top: 40px;
    }

    p {
      color: lighten($black, 50%);
      font-size: 20px;
      margin-top: 20px;
    }

    &__code {
      background: $white;
      border: 1px solid lighten($black, 20%);
      border-radius: 10px;
      cursor: pointer;
      font-size: 32px;
      font-weight: bold;
      margin: 1em auto 60px;
      padding: 30px;
      width: 210px;

      p {
        font-size: inherit;
        margin: 0;
      }
    }
  }
}

@media (max-width: 600px) {
  .poll {
    max-width: 100%;

    &__question {
      font-size: 28px;
      margin: 20px;
    }

    .answer {
      margin: 1em;
    }

    &__thanks {
      h2 {
        font-size: 28px;
        margin-top: 20px;
      }

      p {
        font-size: 18px;
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 450px) {
  .poll {
    .answer {
      &__button {
        height: 43px;

        &__interior {
          font-size: 18px;

          div {
            margin: 0 10px;
          }
        }
      }

      &__social {
        &__button {
          font-size: 16px;
          padding: 13px;
          width: 160px;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .poll {
    &__question {
      font-size: 24px;
    }

    .answer {
      &__button {
        font-size: 22px;
      }
    }
  }
}

@media (max-width: 360px) {
  .poll {
    &__question {
      font-size: 20px;
    }

    .answer {
      &__social {
        flex-direction: column;

        &__button {
          width: 100%;

          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.fancam {
  margin: 0 auto;
  max-width: $container-width;

  &__link {
    border: 2px solid $maroon;
    border-left: 10px solid $maroon;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;

    &__opponent {
      font-size: 24px;
      font-family: 'SourceSansPro-Bold';
    }
    &__date {
      font-size: 20px;
    }

  }
  &__body {
    padding: 0 20px 20px;

    &__text {
      color: $maroon;
      text-align: center;
      font-size: 24px;
      padding: 20px 0;
    }
  }
}

@media (max-width: 600px) {
  .fancam {
    max-width: 100%;
  }
}

@media (max-width: 420px) {
  .fancam {
    &__body {
      &__text {
        font-size: 21px;
      }
    }
    &__link {
      &__opponent {
        font-size: 20px;
      }
      &__date {
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 375px) {
  .fancam {
    &__link {
      &__opponent {
        // font-size: 1em;
      }
      &__date {
        font-size:1em;
      }
    }
  }
}

@media (max-width: 345px) {
  .fancam {
    &__body {
      &__text {
        font-size: 18px;
      }
    }
    &__link {
      &__opponent {
        font-size: 15px;
      }
      &__date {
        font-size: 15px;
      }
    }
  }
}
.traditions {
  margin-top: 20px;
  width: $container-width;

  h2 {
    border-bottom: 1px solid;
    font-family: 'SourceSansPro-Bold';
    font-size: 18px;
    text-transform: uppercase;
  }

  &__songs {
    padding: 10px 20px;

    &.fadeOut {
      display: none;
    }

    &__song {
      &__title {
        font-family: 'SourceSansPro-Bold';
        font-size: 20px;
        margin-top: 0;
      }
    }
  }

  &__overlay {
    background: $overlay-bg;
    height: 100%;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: $container-width;
    z-index: 20;

    &__close {
      color: $white;
      cursor: pointer;
      display: block;
      margin-top: 80px;
      padding-right: 20px;
      text-align: right;
    }

    &__buttons {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 37%;
      width: 100%;
    }

    &__button {
      background: $maroon;
      color: $white;
      padding: 10px 0;
    }

    &__images {
      margin-top: 40px;
      position: relative;

      img {
        display: block;
        user-select: none;
        width: 100%;
      }
    }

    &__words {
      color: $white;
      padding: 20px;

      h2 {
        border: 0;
        color: $gold;
      }
    }
  }

  &__yells {
    align-items: center;
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;

    &.fadeOut {
      display: none;
    }
  }

  &__yell {
    &__gif {
      cursor: pointer;
      display: block;
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .traditions {
    width: 100%;

    &__overlay {
      width: 100%;
    }
  }
}

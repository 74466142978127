.offers {
  margin: 0 auto;
  max-width: $container-width;
  text-align: center;

  &__congrats {
    h2 {
      color: $maroon;
      font-family: 'SourceSansPro-Bold';
      font-size: 36px;
      margin-bottom: 10px;
      margin-top: 20px;
      text-transform: uppercase;
    }

    p {
      color: $gold;
      font-family: 'SourceSansPro-Bold';
      margin: 0 0 20px;
      padding: 0 20px;
    }
  }

  &__form {
    margin-bottom: 30px;
    padding-bottom: 40px;

    &__image {
      width: 100%;
    }

    &__text {
      h2 {
        font-size: 32px;
        margin-bottom: 0;

        strong {
          font-family: 'SourceSansPro-Bold';
        }
      }

      p {
        margin-top: 0;
      }

      &.below-button {
        margin-top: 20px;
      }
    }

    p {
      font-size: 18px;
      font-weight: 100;
      margin: 0 60px 20px;

      strong {
        font-family: 'SourceSansPro-Bold';
      }
    }
  }

  &__button {
    background: $blue;
    border: 1px solid $blue;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: 24px;
    font-weight: 300;
    padding: 10px;
    text-transform: uppercase;
    transition: all .25s;
    width: 50%;

    &:hover {
      background: $white;
      color: $blue;
    }
  }

  &__confirmation {
    margin: 0 auto;
    max-width: $container-width;
    text-align: center;

    &__congrats {
      color: $maroon;
      font-family: 'SourceSansPro-Bold';
      margin-bottom: 0;
      text-transform: uppercase;
    }

    p {
      color: $gold;
      font-family: 'SourceSansPro-Bold';
      margin-top: 5px;
      padding: 0 40px;
    }

    &__image {
      display: block;
      width: 100%;
    }

    &__qr {
      margin-bottom: 65px;
      margin-top: -250px;
      max-width: 200px;
    }

    &__expiration {
      font-size: 22px;
      margin-bottom: 40px;
    }

  }
}

@media (max-width: 600px) {
  .offers,
  .offers__confirmation {
    max-width: 100%;

    &__form {
      p {
        font-size: 16px;
        margin: 0 40px 20px;
      }

      &__input {
        font-size: 16px;
        margin-bottom: 20px;
        padding: 15px;
        width: 85%;
      }
    }
  }
}

@media (max-width: 600px) {
  .offers {
    &__form {
      &__text {
        h2 {
          font-size: 26px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .offers {
    &__congrats {
      h2 {
        font-size: 28px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

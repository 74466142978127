.experience {
  background: $beige;
  margin: 0 auto;
  max-width: $container-width;

  &__body {
    &__button {
      background: $pepsi-blue;
      color: $beige;
      cursor: pointer;
      display: block;
      font-family: 'Moriston-Regular';
      font-size: 24px;
      margin: 20px auto 40px;
      padding: 10px 0;
      text-align: center;
      width: 200px;
    }
  }

  &__image {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .experience {
    max-width: 100%;
  }
}

$bg: #f6f5f5;

.countdown {
  background: $bg;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: $container-width;

  &__header,
  &__opponent {
    display: block;
    width: 100%;
  }

  &__happening {
    border: 2px solid $gold;
    font-family: 'Tungsten-Medium';
    font-size: 42px;
    margin: 30px 30px 0;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
  }

  &__timer {
    border: 2px solid $gold;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-content: space-between;
    margin: 30px 30px 0;
    text-align: center;

    &__fourth {
      border-right: 2px solid $gold;
      margin: 20px 0;

      &:last-child {
        border-right: 0;
      }
    }

    &__number {
      color: $maroon;
      font-family: 'Pride';
      font-size: 64px;
    }

    &__unit {
      color: $maroon;
      font-family: 'Tungsten-Medium';
      font-size: 20px;
    }
  }

  &__sponsors {
    background: $maroon;
    color: $white;
    font-family: 'Moriston-Regular';
    font-size: 20px;
    padding: 20px 20px 30px;
    text-align: center;

    &__grid {
      display: grid;
      grid-column-gap: 2px;
      grid-row-gap: 2px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 20px auto 10px;
    }
  }

  &__sponsor {
    align-items: center;
    background: $bg;
    display: flex;

    &__image {
      display: block;
      margin: 20px auto;
      width: 60px;
    }
  }

  &__footer {
    display: block;
    width: 100%;
  }
}


@media (max-width: 420px) {
  .countdown {
    &__timer {
      &__number {
        font-size: 46px;
      }

      &__unit {
        font-size: 18px;
      }
    }

    &__sponsor {
      &__image {
        width: 50px;
      }
    }
  }
}

// @media (max-width: 500px) {
//   .countdown {
//     &__sponsor {
//       &__image {
//         padding: 10px 15px;
//       }
//     }
//   }
// }

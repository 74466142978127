.sport-select {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: $container-width;
  min-height: 95vh;

  &__arrow {
    animation-duration: 2s !important;
  }

  h3 {
    background: $grey;
    font-weight: bold;
    margin: 0;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;
  }

  &__body {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  &__nav {
    background: lighten($nav-grey, 2%);
    display: flex;
    flex-direction: column;
    width: 35%;
  }

  &__links {
    color: $grey;
    cursor: pointer;
    display: block;
    font-family: 'Tungsten-Medium';
    font-size: 28px;
    margin: 15px 0;
    padding: 30px;

    &.active {
      border-left: 10px solid $maroon;
      padding-left: 20px;
    }
  }

  &__main {
    background-color: $maroon;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;

    &__live {
      animation-duration: 2.5s !important;
      background: $red;
      color: $white;
      cursor: default;
      font-family: 'Tungsten-Medium';
      font-size: 22px;
      margin-left: 20px;
      padding: 5px 10px;
      text-align: center;
      width: 50px;
    }

    &__body {
      position: absolute;
      top: 40%;
    }

    &__text {
      color: $white;
      cursor: pointer;
      font-size: 48px;
      font-weight: bold;
      padding: 20px;
    }
  }
}

@media (max-width: 600px) {
  .sport-select {
    width: 100%;

    &__links {
      &.active {
        border-left: 7px solid $maroon;
        padding-left: 23px;
      }
    }
  }
}

@media (max-width: 500px) {
  .sport-select {
    &__links {
      font-size: 22px;
      margin: 5px 0;
      padding: 20px;

      &.active {
        padding-left: 13px;
      }
    }

    &__main {
      &__text {
        font-size: 38px;
      }
    }
  }
}

@media (max-width: 440px) {
  .sport-select {
    h3 {
      font-size: 16px;
    }

    // &__links {
    //   font-size: 24px;
    //   margin: 10px 0;
    // }

    &__main {
      &__text {
        font-size: 30px;
      }
    }
  }
}

@media (max-width: 380px) {
  .sport-select {
    h3 {
      font-size: 14px;
    }

    &__links {
      font-size: 20px;
      // margin: 10px 0;
      padding: 15px;

      &.active {
        padding-left: 8px;
      }
    }

    &__main {
      &__text {
        font-size: 28px;
        padding: 20px;
      }
    }
  }
}

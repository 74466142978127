.drop-nav {
  background: $maroon;
  display: block;
  height: 0%;
  left: 0;
  margin: 20px auto 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  transition: height .3s;
  width: $container-width;
  z-index: 5;

  &__category {
    &__title {
      background: $maroon;
      color: $white;
      font-family: 'SourceSansPro-Bold';
      font-size: 22px;
      margin: 0 auto 1px;
      padding: 10px 0 10px 30px;
      text-align: left;
      text-transform: uppercase;
    }
  }

  &__open {
    height: 95vh;

    a {
      display: block;
    }
  }

  &__closed {
    height: 0%;

    a {
      display: none;
    }
  }

  &__links {
    background: $white;
    color: $maroon;
    display: block;
    font-family: 'SourceSansPro-Bold';
    font-size: 18px;
    font-weight: bold;
    padding: 12px 30px;
    text-transform: uppercase;

    &--active {
      background: $white;
      color: $maroon;

      &:hover {
        background: $white;
      }
    }
  }
}

.nav-enter {
  opacity: .01;
}

.nav-enter.nav-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.nav-leave {
  opacity: 1;
}

.nav-leave.nav-leave-active {
  opacity: .01;
  transition: opacity 100ms ease-in;
}

@media (max-width: 600px) {
  .drop-nav {
    margin-top: 15px;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .drop-nav {
    margin-top: 10px;

    &__links {
      font-size: 18px;
    }
  }
}

.promos {
  margin: 0 auto;
  max-width: $container-width;

  &__rows {
    padding: 20px;
  }

  &__row {
    border-bottom: 1px solid $grey;
    display: block;
    margin-bottom: 20px;

    &:last-child {
      border-bottom: 0;
    }

    &__heading {
      align-items: center;
      display: grid;
      grid-template-columns: 20% 80%;
      margin-bottom: 15px;
    }

    &__image {
      display: block;
      width: 100%;
    }

    &__copy {
      margin-left: 25px;
      margin-right: 15px;

      &--small {
        color: $dark-gray;
      }

      h3 {
        color: $maroon;
        font-size: 24px;
        font-weight: bold;
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 600px) {
  .promos {
    max-width: 100%;
  }
}

@media (max-width: 470px) {
  .promos {
    &__row {
      &__copy {
        h3 {
          font-size: 20px;
          margin-bottom: 5px;
        }
        &--small {
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
}

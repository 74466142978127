.footer-nav {
  background: $white;
  padding-bottom: 30px;
  padding-top: 1px;
  width: $container-width;

  &__chunk {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  h2 {
    &.footer-nav__explore {
      background: $white;
      color: $gold;
      font-family: 'SourceSansPro-Bold';
      font-size: 22px;
      margin: 0;
      padding: 10px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  a {
    border-bottom: 1px solid $grey;
    color: $maroon;
    display: block;
    font-size: 16px;
    font-weight: 100;
    margin: 4px 20px;
    padding: 10px;
    text-align: left;
  }

  &__categories {
    background: $grey;
    border-bottom: 1px solid $white;
    color: $maroon;
    display: flex;
    justify-content: space-around;
    padding: 10px;

    &__option {
      background: $transparent;
      border-radius: 20px;
      cursor: pointer;
      font-size: 16px;
      padding: 5px 15px 8px;
      transition: color .35s, background .35s;

      &.active {
        background: $maroon;
        color: $white;
      }
    }
  }

  &__large {
    background: $grey;

    &.darken {
      a {
        color: $black;
      }
    }

    &.pad-bottom {
      padding-bottom: 30px;
    }

    a {
      border-bottom: 0;
      color: $grey;
      margin: 15px 20px 0;
      padding: 0;
    }

    &__sponsor {
      align-items: center;
      background: $transparent;
      display: flex;
      height: 0;
      margin-right: 20px;
      width: 70px;

      img {
        display: block;
        width: 100%;
      }
    }

    &__main {
      font-family: 'SourceSansPro-Bold';
      min-height: 275px;
      position: relative;
    }

    &__bg {
      border-radius: 5px;
      box-shadow: 0 2px 8px -1px lighten($black, 50%);
      display: block;
      position: absolute;
      width: 100%;
      z-index: 0;
    }

    &__bottom {
      align-items: center;
      bottom: 20px;
      display: flex;
      justify-content: space-between;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    &__words {
      margin-left: 20px;
    }

    &__title {
      font-size: 16px;
    }

    &__text {
      font-size: 20px;
    }
  }

  &__small {
    background: $grey;

    a {
      border-bottom: 0;
      border-radius: 5px;
      box-shadow: 0 2px 5px -1px lighten($black, 50%);
      color: $grey;
      display: flex;
      margin: 10px 20px 5px;
      padding: 0;
    }

    &:first-child {
      a {
        padding-top: 10px;
      }
    }

    &:last-child {
      a {
        margin-bottom: 20px;
      }
    }

    &.no-top-pad {
      a {
        padding-top: 0;
      }
    }

    &__sponsor {
      align-items: center;
      background: $white;
      border-radius: 5px 0 0 5px;
      display: flex;
      min-height: 100px;
      width: 105px;

      img {
        display: block;
        padding: 10px;
        width: 100%;
      }
    }

    &__main {
      background-color: lighten($black, 20%);
      background-size: cover;
      border-radius: 0 5px 5px 0;
      flex-grow: 1;
      font-family: 'SourceSansPro-Bold';
      font-weight: bold;
      padding: 20px;
    }

    &__title {
      font-size: 16px;
    }

    &__text {
      font-size: 16px;
    }
  }

  &__support {
    margin: 0 20px;

    h2 {
      color: $maroon;
      font-family: 'SourceSansPro-Bold';
      font-size: 22px;
      margin-bottom: 5px;
      margin-left: 10px;
      text-align: left;
    }

    a {
      background: $transparent;
      border-bottom: 1px solid $grey;
      color: $maroon;
      font-size: 16px;
      margin: 0;
      padding: 10px;
      text-align: left;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

@media (max-width: 600px) {
  .footer-nav {
    width: 100%;

    &__support {
      a {
        margin: 0;
      }
    }
  }
}

@media (max-width: 580px) {
  .footer-nav {
    &__large {
      &__main {
        min-height: 260px;
      }
    }
  }
}

@media (max-width: 540px) {
  .footer-nav {
    &__large {
      &__main {
        min-height: 240px;
      }
    }
  }
}

@media (max-width: 520px) {
  .footer-nav {
    &__large {
      &__main {
        min-height: 230px;
      }
    }
  }
}

@media (max-width: 500px) {
  .footer-nav {
    &__large {
      &__main {
        min-height: 220px;
      }
    }
  }
}

@media (max-width: 460px) {
  .footer-nav {
    &__large {
      &__main {
        min-height: 210px;
      }
    }
  }
}

@media (max-width: 440px) {
  .footer-nav {
    &__large {
      &__main {
        min-height: 195px;
      }
    }
  }
}

@media (max-width: 420px) {
  .footer-nav {
    &__categories {
      &__option {
        font-size: 14px;
        padding: 5px 9px 7px;
      }
    }

    &__large {
      &__main {
        min-height: 185px;
      }

      &__title {
        font-size: 14px;
      }

      &__text {
        font-size: 18px;
      }

      &__sponsor {
        width: 60px;
      }
    }

    &__small {
      &__title {
        font-size: 14px;
      }

      &__text {
        font-size: 14px;
      }
    }

    a {
      font-size: 14px;
    }

    &__explore {
      font-size: 20px;
    }

    &__support {
      h2 {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 400px) {
  .footer-nav {
    &__large {
      &__main {
        min-height: 180px;
      }
    }
  }
}

@media (max-width: 380px) {
  .footer-nav {
    &__large {
      &__main {
        min-height: 170px;
      }

      &__title {
        font-size: 12px;
      }

      &__text {
        font-size: 16px;
      }

      &__sponsor {
        width: 50px;
      }
    }

    &__small {
      &__title {
        font-size: 12px;
      }

      &__text {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 360px) {
  .footer-nav {
    &__large {
      &__main {
        min-height: 160px;
      }
    }
  }
}

@media (max-width: 340px) {
  .footer-nav {
    &__categories {
      &__option {
        font-size: 12px;
        padding: 5px 9px 7px;
      }
    }

    &__large {
      &__main {
        min-height: 155px;
      }
    }
  }
}

@media (max-width: 330px) {
  .footer-nav {
    &__categories {
      &__option {
        font-size: 12px;
        padding: 5px 9px 7px;
      }
    }

    &__large {
      &__main {
        min-height: 145px;
      }

      &__title {
        font-size: 10px;
      }

      &__text {
        font-size: 14px;
      }
    }

    &__small {
      &__title {
        font-size: 10px;
      }

      &__text {
        font-size: 12px;
      }
    }
  }
}

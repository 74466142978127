.suggestions {
  margin: 0 auto;
  max-width: $container-width;
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__body {
    padding: 20px;

    &__text {
      margin: 0 auto;

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__button {
      background: $gold;
      color: $white;
      display: block;
      font-size: 20px;
      margin: 0 auto;
      max-width: 275px;
      padding: 10px 15px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

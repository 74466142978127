@import 'preview/traditions';

.preview {
  display: block;
  margin: 0 auto;
  max-width: $container-width;

  &__nav {
    box-shadow: $shadow-default;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__link {
    color: lighten($black, 20%);
    font-family: 'SourceSansPro-Bold';
    padding: 7px 0 10px;
    text-align: center;
    text-transform: uppercase;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }

    &::after {
      background: transparent;
      content: '';
      display: block;
      height: 3px;
      margin: auto;
      margin-bottom: -10px;
      margin-top: 6px;
      transition: width .5s ease, background-color .5s ease;
      width: 0;
    }

    &__active {
      color: $gold;

      &::after {
        background: $gold;
        width: 100%;
      }
    }
  }

  &__bullet-title {
    color: $maroon;
    font-weight: bold;
  }

  &__social-links {
    display: flex;
    justify-content: space-around;
    margin: 0 auto 2em;
    width: 100;
  }

  &__coupon {
    cursor: pointer;
    margin: 40px auto;
    width: 100%;
  }
}

.preview-main {
  padding: 0 20px;
  width: $container-width;

  div {
    h2 {
      margin: 20px auto;
    }
  }

  b {
    font-family: 'SourceSansPro-Bold';
  }

  h2 {
    font-family: 'SourceSansPro-Bold';
    text-transform: uppercase;
  }

  ul {
    padding-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }

  ol {
    margin-top: 10px;
    padding-left: 20px;

    li {
      margin-bottom: 7px;

      strong {
        color: $maroon;
        font-family: 'SourceSansPro-Bold';
      }
    }
  }
}

@media (max-width: 600px) {
  .preview {
    width: 100%;
  }

  .preview-main {
    width: 100%;

    h2 {
      font-size: 18px;
    }
  }
}

@media (max-width: 430px) {
  .preview {
    .preview-main {
      ol {
        li {
          font-size: 14px;
        }
      }
    }

    &__social-links {
      flex-direction: column;
      margin-bottom: 20px;
      padding: 0 20px;
    }
  }
}

@media (max-width: 400px) {
  .preview {
    &__link {
      font-size: 16px;
      padding: 5px 0 10px;
    }

    &__coupon {
      margin-bottom: 20px;
    }
  }
}

.tickets {
  margin: 0 auto;
  max-width: $container-width;
  text-align: center;

  #phizzle-tickets {
    background: lighten($dark-gray, 70%);
    border: 0;
    height: 200px;
    margin-bottom: 10px;
    margin-top: -5px;
    width: 100%;
  }

  &__header {
    display: block;
    width: 100%;
  }

  &__body {
    margin: 0 20px;

    p {
      color: $maroon;
      font-size: 14px;

      a {
        border-bottom: 1px solid $maroon;
      }
    }
  }

  &__form {
    margin: 0 60px 20px;
  }

  &-confirmation {
    margin: 0 auto;
    max-width: $container-width;
    text-align: center;

    &__header {
      &__text {
        background: $maroon;
        color: $white;
        padding: 30px;

        h2 {
          font-family: 'SourceSansPro-Bold';
          font-size: 36px;
          margin: 0;
          text-transform: uppercase;
        }

        p {
          font-size: 20px;
          margin: 10px 0;
        }
      }
    }

    &__middle {
      &__text {
        color: $maroon;
        display: block;
        margin: 20px;
      }

      display: block;
      width: 100%;
    }

    &__form {
      padding: 0 60px 20px;
    }

    &__thanks {
      color: $maroon;
      font-size: 18px;
      padding: 10px 20px;
    }
  }
}

$opaque-white: rgba(255, 255, 255, .7);

.sponsor-card {
  align-items: center;
  background: $opaque-white;
  border: 1px solid $maroon;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 160px;
  justify-content: space-around;
  width: 100%;

  &__image {
    display: block;
    max-height: 80px;
    max-width: 160px;
    padding-top: 10px;
  }

  &__hr {
    border: 0;
    border-top: 1px solid $maroon;
    display: block;
    height: 1px;
    margin: 0 auto;
    padding: 0;
    width: 75%;
  }

  &__title {
    font-size: 18px;
    margin: 0;
    text-transform: uppercase;
  }

  &__icon {
    margin-top: -35px;
    width: 90%;

    &__image {
      float: right;
    }
  }
}

@media (max-width: 500px) {
  .sponsor-card {
    height: 130px;

    &__image {
      max-height: 60px;
      max-width: 120px;
    }

    &__title {
      padding-bottom: 10px;
    }

    &__icon {
      &__image {
        height: 15px;
        width: 15px;
      }
    }
  }
}

@media (max-width: 400px) {
  .sponsor-card {
    height: 110px;

    &__image {
      max-height: 50px;
      max-width: 100px;
    }

    &__title {
      font-size: 16px;
      padding-bottom: 20px;
    }

    &__icon {
      &__image {
        height: 10px;
        width: 10px;
      }
    }
  }
}

@media (max-width: 350px) {
  .sponsor-card {
    height: 110px;

    &__title {
      font-size: 15px;
      padding-bottom: 25px;
    }

    &__icon {
      &__image {
        display: none;
      }
    }
  }
}

.chat {
  margin: 0 auto;
  max-width: $container-width;

  &__body {
    padding: 0 20px 20px;

    &__text {
      p {
        &:first-child {
          margin-top: 10px;
        }

        strong {
          font-family: 'SourceSansPro-Bold';
        }
      }
    }
  }
}

.rosters {
  display: block;
  margin: 0 auto;
  max-width: $container-width;
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__body {
    margin-bottom: 40px;
  }

  &__nav {
    display: flex;
    justify-content: space-between;

    &__link {
      cursor: pointer;
      font-size: 20px;
      margin: 0;
      padding: 20px 0;

      &::after {
        background: transparent;
        content: '';
        display: block;
        height: 3px;
        margin: auto;
        margin-bottom: -10px;
        margin-top: 2px;
        transition: width .5s ease, background-color .5s ease;
        width: 0;
      }

      &.active {
        color: $gold;

        &::after {
          background: $gold;
          width: 100%;
        }
      }
    }
  }

  &__player {
    border-bottom: 1px solid $maroon-light;
    padding: 5px 0;

    &:nth-child(even) {
      background: linear-gradient(to right, darken($white, 2%), lighten($white, 100%));
    }

    &:last-child {
      border-bottom: 0;
      margin-bottom: 20px;
    }

    &__intro {
      display: grid;
      font-family: 'SourceSansPro-Bold';
      grid-template-columns: 15% 65% 10% 10%;
    }

    &__name,
    &__pos {
      display: inline-block;
    }

    &__chevron {
      cursor: pointer;
      margin-right: 10px;
      margin-top: 3px;
      text-align: right;

      &::before {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: .45em;
        left: .15em;
        position: relative;
        top: .15em;
        transform: rotate(-45deg);
        transition: all .3s ease;
        vertical-align: top;
        width: .45em;
      }

      &.down {
        &::before {
          top: 0;
          transform: rotate(-225deg);
        }
      }
    }

    &__extra {
      align-items: center;
      display: grid;
      grid-template-columns: 35% 55%;
      margin: 10px 0;
    }

    &__image {
      border-radius: 2px;
      height: 100%;

      &--container {
        height: 120px;
        // margin-left: 15px;
        position: relative;
        width: 87px;
      }
    }

    &__extended {
      &.no-picture {
        display: block;
        padding-left: 10%;
      }
      
      strong {
        font-family: 'SourceSansPro-Bold';
      }
    }
  }
}

@media (max-width: 430px) {
  .rosters {
    &__player {
      font-size: 14px;
    }
  }
}

$container-width: 600px;

@import 'colors';
@import 'fonts';
@import 'components';
@import 'containers';
@import 'animate';

* {
  box-sizing: border-box;
  // transition: all .06s ease;
}

body {
  // background: darken($white, 1%);
  color: lighten($black, 30%);
  font-family: 'SourceSansPro-Regular';
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
}

a {
  color: $maroon;
  text-decoration: none;
}

button,
input {
  &:focus {
    outline: none;
  }
}

.alfaslab {
  font-family: 'AlfaSlabOne-Regular';
  font-weight: normal;
}

.app {
  min-height: 100vh;
}

.error-loading {
  background: $maroon;
  min-height: 100vh;
  width: 100%;
  padding-top: 40px;
  text-align: center;
}

//
// @media (max-width: 600px) {
//   .app {
//     max-width: 100%;
//   }
// }

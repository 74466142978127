$beige: #f7f5ee;
$black: #000;
$blue: #278bc6;
$dark-gray: #2b2b29;
$nav-grey: #252525;
$facebook-blue: #334f8d;
$gold: #a5a563;
$grey: #ebebeb;
$maroon-light: #64343d;
$maroon: #500000;
$orange: #fba919;
$marker-red: #720000;
$red: #e1021e;
$overlay-bg: rgba(0, 0, 0, .8);
$pepsi-blue: #0469a9;
$transparent: rgba(0, 0, 0, 0);
$twitter-blue: #1da1f3;
$white: #f7f7f7;
$modal-opacity: rgba(0, 0, 0, .8);
$shadow-default: 0 10px 18px -1px lighten($black, 60%);

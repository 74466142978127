@font-face {
  font-family: 'AlfaSlabOne-Regular';
  font-weight: bold;
  src: url('/fonts/AlfaSlabOne-Regular.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Pride';
  font-weight: bold;
  src: url('/fonts/PRIDE.otf') format('opentype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Impact';
  font-weight: bold;
  src: url('/fonts/impact.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Moriston-Regular';
  font-weight: bold;
  src: url('/fonts/Moriston-Regular.otf') format('opentype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'Tungsten-Medium';
  font-weight: bold;
  src: url('/fonts/Tungsten-Medium.otf') format('opentype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'PermanentMarker';
  font-weight: bold;
  src: url('/fonts/PermanentMarker.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'SourceSansPro-Bold';
  font-weight: bold;
  src: url('/fonts/SourceSansPro-Bold.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'SourceSansPro-Light';
  font-weight: bold;
  src: url('/fonts/SourceSansPro-Light.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'SourceSansPro-Regular';
  font-weight: bold;
  src: url('/fonts/SourceSansPro-Regular.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'OpenSans-Bold';
  font-weight: bold;
  src: url('/fonts/OpenSans-Bold.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'OpenSans-Regular';
  font-weight: 500;
  src: url('/fonts/OpenSans-Regular.ttf') format('truetype');
  text-rendering: optimizeLegibility;
}

.heat-sheet {
  display: block;
  margin: 0 auto;
  max-width: $container-width;

  &__info {
    border-bottom: 1px solid $gold;
    display: flex;
    justify-content: space-between;

    p {
      margin: 5px 15px;
    }
  }
}

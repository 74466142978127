.next-game {
  &__happening {
    border-bottom: 2px solid $white;
    border-top: 2px solid $white;
    color: $white;
    font-family: 'Tungsten-Medium';
    font-size: 42px;
    margin: 30px 30px 0;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
  }

  &__timer {
    // border: 2px solid $white;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-content: space-between;
    margin: 0 10px;
    text-align: center;

    &__fourth {
      border-right: 1px solid $white;
      cursor: default;
      padding: 11px 0 10px;

      &:last-child {
        border-right: 0;
      }
    }

    &__number {
      color: $white;
      font-family: 'Pride';
      font-size: 48px;
    }

    &__unit {
      color: $white;
      font-family: 'Tungsten-Medium';
      font-size: 20px;
    }
  }
}


@media (max-width: 460px) {
  .next-game {
    &__timer {
      &__number {
        font-size: 38px;
      }

      &__unit {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 360px) {
  .next-game {
    &__timer {
      &__number {
        font-size: 28px;
      }

      &__unit {
        font-size: 16px;
      }
    }
  }
}

.stats {
  margin: 0 auto;
  max-width: $container-width;

  &__nav {
    box-shadow: $shadow-default;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    width: $container-width;
  }

  &__link {
    color: lighten($black, 20%);
    cursor: pointer;
    font-family: 'SourceSansPro-Bold';
    font-size: 18px;
    padding: 5px 0 10px;
    text-align: center;
    text-transform: uppercase;

    &::after {
      background: transparent;
      content: '';
      display: block;
      height: 5px;
      margin: auto;
      margin-bottom: -10px;
      margin-top: 6px;
      transition: width .5s ease, background-color .5s ease;
      width: 0;
    }

    &.active {
      color: $gold;

      &::after {
        background: $gold;
        width: 100%;
      }
    }
  }

  &__tables {
    margin: 20px 0;

    &__toss {
      font-family: 'SourceSansPro-Bold';
      margin-top: 0;
    }

    &__era {
      font-family: 'SourceSansPro-Bold';
      margin: 10px 20px;
    }
  }

  &__table {
    &__title {
      background: $maroon;
      color: $white;
      font-family: 'SourceSansPro-Bold';
      font-size: 18px;
      margin: 0 auto 1px;
      padding: 10px 0 10px 15px;
      text-align: left;
      text-transform: uppercase;
    }

    &__body {
      border-spacing: 0;
      margin-bottom: 20px;
      width: 100%;

      thead {
        tr {
          td {
            color: darken($gold, 5%);
            font-family: 'SourceSansPro-Bold';
            font-size: 16px;
            padding-bottom: 5px;
            padding-top: 5px;

            &.numeric {
              text-align: center;

              &:last-child {
                padding-right: 15px;
              }
            }

            &:first-child {
              padding-left: 15px;
            }
          }
        }
      }

      tbody {
        tr {
          &:nth-child(odd) {
            background: lighten($gold, 35%);
          }

          td {
            padding-bottom: 5px;
            padding-top: 5px;

            &.numeric {
              text-align: center;

              &:last-child {
                padding-right: 15px;
              }
            }

            &:first-child {
              padding-left: 15px;
            }
          }
        }
      }

    }

    &__chevron {
      cursor: pointer;
      float: right;
      margin-right: 30px;
      margin-top: 3px;

      &::before {
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: '';
        display: inline-block;
        height: .45em;
        left: .15em;
        position: relative;
        top: .15em;
        transform: rotate(-45deg);
        transition: all .3s ease;
        vertical-align: top;
        width: .45em;
      }

      &.down {
        &::before {
          top: 0;
          transform: rotate(-225deg);
        }
      }
    }
  }

  &__form {
    &__ad {
      width: 100%;
    }

    #phizzle-weboptin {
      background: lighten($dark-gray, 70%);
      border: 0;
      height: 360px;
      margin-bottom: 10px;
      margin-top: -5px;
      width: 100%;
    }

    &__body {
      background: $dark-gray;
      color: $white;
      margin: -5px 0 20px;
      padding-bottom: 30px;
      text-align: center;

      p {
        font-size: 18px;
        font-weight: 100;
        margin: 0;
        padding: 20px 0 15px;
      }
    }

    &__button {
      background: $blue;
      border: 1px solid $blue;
      color: $white;
      cursor: pointer;
      display: inline-block;
      font-size: 24px;
      padding: 8px;
      text-transform: uppercase;
      transition: all .25s;
      width: 50%;
    }
  }
}

@media (max-width: 600px) {
  .stats {
    max-width: 100%;

    &__nav {
      font-size: 14px;
      width: 100%;
    }

    &__table {
      font-size: 14px;

      &__title {
        font-size: 16px;
      }

      &__body {
        thead {
          tr {
            td {
              font-size: 14px;
            }
          }
        }
      }
    }

    &__form {
      #phizzle-weboptin {
        height: 450px;
      }

      &__body {
        p {
          font-size: 14px;
        }

        input {
          font-size: 16px;
          padding: 10px;
        }
      }
    }
  }
}

// @media (max-width: 480px) {
//   .stats {
//     &__form {
//       #phizzle-weboptin {
//         height: 550px;
//       }
//     }
//   }
// }

@media (max-width: 380px) {
  .stats {
    &__form {
      #phizzle-weboptin {
        height: 500px;
      }
    }
  }
}

@media (max-width: 360px) {
  .stats {
    &__link {
      font-size: 16px;
    }
  }
}

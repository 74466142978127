// sass-lint:disable no-vendor-prefixes
.social-button {
  -webkit-appearance: none;
  // background: darken($white, 5%);
  border: 2px solid $maroon;
  color: $maroon;
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 15px;

  svg {
    margin-right: 10px;
    margin-top: -3px;
  }
}

.submit-button {
  background: $blue;
  border: 1px solid $blue;
  color: $white;
  cursor: pointer;
  font-size: 24px;
  font-weight: 300;
  padding: 10px;
  text-transform: uppercase;
  transition: all .25s;
  width: 50%;

  &:hover {
    background: $white;
    color: $blue;
  }
}

@media (max-width: 500px) {
  .social-button {
    font-size: 15px;
  }

  .submit-button {
    font-size: 18px;
  }
}

@media (max-width: 430px) {
  .social-button {
    margin-bottom: 10px;
  }
}

.header {
  align-items: center;
  // background: url('/img/menu-gradient.png');
  background: $maroon;
  background-size: cover;
  color: $white;
  display: flex;
  justify-content: space-between;
  left: 0;
  margin: 0 auto;
  padding: 12px 0 10px;
  position: fixed;
  right: 0;
  top: 0;
  width: $container-width;
  z-index: 100000;

  &__default {
    color: $white;
    font-family: 'AlfaSlabOne-Regular';
    font-size: 26px;
    letter-spacing: .5px;
    margin: 0 auto;
    text-transform: uppercase;
  }

  &__landing {
    align-items: center;
    display: flex;
  }

  &__link {
    align-items: center;
    display: flex;
    width: calc(100% - 65px);

    &--full {
      width: 100%;
    }

    &__logo {
      height: 50px;
      margin-left: 20px;
    }

    &__vs {
      color: $white;
      font-size: 18px;
      padding-left: 20px;
      text-transform: uppercase;
    }

    &__opponent {
      margin-left: 10px;
      max-height: 45px;
    }
  }

  &__center {
    cursor: pointer;
    font-family: 'AlfaSlabOne-Regular';
    font-size: 24px;
    font-weight: normal;
    margin: 0;
  }

  &__menu {
    display: block;
    margin-right: 20px;
  }
}

#nav-burger {
  cursor: pointer;
  height: 25px;
  margin: 0 auto;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  width: 25px;

  span {
    background: $white;
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    width: 50%;

    &:nth-child(even) {
      left: 49%;
    }

    &:nth-child(odd) {
      left: 0;
    }

    &:nth-child(1),
    &:nth-child(2) {
      top: 0;
    }

    &:nth-child(3),
    &:nth-child(4) {
      top: 8px;
    }

    &:nth-child(5),
    &:nth-child(6) {
      top: 16px;
    }
  }

  &.open {
    span {
      &:nth-child(1),
      &:nth-child(6) {
        transform: rotate(45deg);
      }

      &:nth-child(2),
      &:nth-child(5) {
        transform: rotate(-45deg);
      }

      &:nth-child(1) {
        left: 0;
        top: 7px;
      }

      &:nth-child(2) {
        left: calc(50% - 3px);
        top: 7px;
      }

      &:nth-child(3) {
        left: -50%;
        opacity: 0;
      }

      &:nth-child(4) {
        left: 100%;
        opacity: 0;
      }

      &:nth-child(5) {
        left: 0;
        top: 17px;
      }

      &:nth-child(6) {
        left: calc(50% - 3px);
        top: 17px;
      }
    }
  }
}

#header-gap {
  height: 72px;
}

@media (max-width: 600px) {
  .header {
    width: 100%;

    &__link {
      &__logo {
        height: 36px;
      }
    }

    &__menu {
      &__burger {
        svg {
          height: 36px;
          width: 36px;
        }
      }
    }
  }

  #header-gap {
    height: 58px;
  }
}

@media (max-width: 450px) {
  .header {
    &__logo {
      height: 28px;
      margin-left: 20px;
      width: 28px;
    }

    &__default {
      font-size: 18px;
    }

    &__center {
      font-size: 18px;
    }

    &__menu {
      &__burger {
        margin: 0 20px 0 0;

        svg {
          height: 28px;
          width: 28px;
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .header {
    &__logo {
      height: 22px;
      width: 22px;
    }

    &__default {
      font-size: 16px;
    }

    &__center {
      font-size: 16px;
    }

    &__menu {
      &__burger {
        svg {
          height: 22px;
          width: 22px;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .header {
    &__logo {
      height: 18px;
      width: 18px;
    }

    &__center {
      font-size: 14px;
    }

    &__menu {
      &__burger {
        svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }
}

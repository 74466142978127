.check {
  margin: 20px auto 10px;
  text-align: left;
  width: 100%;

  a {
    color: $white;
    cursor: pointer;
    text-decoration: underline;
  }

  &-box {
    align-items: center;
    color: $maroon;
    display: flex;
    font-size: 16px;
    line-height: 20px;
    margin: 15px 0;
    padding-left: 30px;
    position: relative;

    input {
      &[type='checkbox'] {
        display: none;
        line-height: normal;
        margin: 4px 0 0;
        visibility: hidden;

        &:checked + label {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    label {
      background: $transparent;
      border: solid 2px $maroon;
      cursor: pointer;
      display: inline-block;
      height: 25px;
      left: 0;
      position: absolute;
      // top: 0;
      transition: all linear 300ms;
      width: 25px;

      &::after {
        background: $transparent;
        border: 2px solid $maroon;
        border-right: 0;
        border-top: 0;
        content: '';
        height: 6px;
        left: 4px;
        opacity: 0;
        position: absolute;
        top: 4px;
        transform: rotate(-45deg);
        transition: all linear 200ms;
        width: 12px;
      }
    }

    span {
      display: inline-block;
      padding-left: 10px;
    }
  }
}

@media (max-width: 400px) {
  .check {
    &-box {
      font-size: 15px;
    }
  }
}

@media (max-width: 350px) {
  .check {
    margin-top: 10px;
  }
}

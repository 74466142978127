.scoreboards {
  margin: 0 auto;
  max-width: $container-width;
  text-align: center;
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__body {
    padding: 0 0 40px;
  }
}

.conference {
  // border-bottom: 2px solid $white;

  &__header {
    align-items: center;
    background: $maroon;
    color: $white;
    width: 100%;
  }

  &__name {
    font-family: 'SourceSansPro-Bold';
    font-size: 18px;
    margin-bottom: 5px;
    padding: 10px 0 0 15px;
    text-align: left;
    text-transform: uppercase;
  }
}

.scoreboard {
  border-bottom: 5px solid $gold;
  margin: 10px 20px;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: 0;
  }

  &__team {
    align-items: center;
    display: grid;
    grid-template-columns: 95% 5%;
    padding: 5px 20px;
    text-align: left;
    width: 100%;

    &:first-child {
      border-bottom: 1px solid lighten($black, 20%);
      padding-bottom: 10px;
    }

    &__win {
      color: $gold;
    }

    &__name {
      font-family: 'SourceSansPro-Bold';
      font-size: 18px;
    }

    &__record {
      font-family: 'SourceSansPro-Regular';
      font-size: 14px;
    }

    &__score {
      font-family: 'SourceSansPro-Bold';
      font-size: 22px;
    }
  }
}

@media (max-width: 600px) {
  .scoreboards {
    max-width: 100%;
  }

  .conference {
    &__name {
      font-size: 16px;
    }
  }
}

@media (max-width: 460px) {
  .scoreboard {
    &__team {
      &__name {
        font-size: 16px;
      }

      &__record {
        font-size: 12px;
      }

      &__score {
        font-size: 18px;
      }
    }
  }
}

.scratch {
  background: lighten($dark-gray, 10%);
  display: block;
  margin: 0 auto;
  max-width: $container-width;

  &__form {
    display: block;
    position: absolute;
    top: 100px;
    width: 100%;
    z-index: 30;

    &__container {
      display: block;
      height: 550px;
      margin-top: -110px;
      position: relative;
    }

    &__bg {
      display: block;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    &__copy {
      display: grid;
      grid-template-columns: 30% 60%;
      margin: 40px auto;
      width: 90%;

      h2 {
        color: $marker-red;
        font-family: 'PermanentMarker';
        font-size: 36px;
        margin: 0;
        text-align: center;
      }

      .reg {
        margin-top: 7px;

        &__bold {
          color: $black;
          font-family: 'Tungsten-Medium';
          font-size: 32px;
        }

        &__norm {
          font-size: 22px;

          a {
            border-bottom: 1px solid $dark-gray;
            color: $dark-gray;
            cursor: pointer;
          }
        }
      }
    }

    &__input {
      border: 0;
      display: block;
      font-family: 'PermanentMarker';
      font-size: 24px;
      margin: 0 auto;
      width: 80%;
    }

    &__underline {
      display: block;
      margin: 0 auto 70px;
      width: 85%;
    }

    &__submit {
      background: none;
      border: 0;
      cursor: pointer;
      display: block;
      float: right;
      padding: 0;
      width: 200px;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  &__facts {
    font-family: 'PermanentMarker';
    font-size: 28px;
    text-align: center;
    width: 100%;

    &__maroon {
      color: $maroon;
      padding: 70px 40px 0;
    }

    &__fact {
      color: $black;
      padding: 10px 40px 160px;
    }
  }

  &__prize {
    padding: 0 20px;
  }

  &__body {
    margin: 30px;
    position: relative;
  }

  &__sticky {
    display: block;
    margin: 30px auto 20px;
    width: 90%;
  }

  &__collage {
    background-size: cover;
    padding: 1px 0 45px;
    z-index: -1;
  }

  &__todays-prize {
    display: block;
    margin: 0 auto;
    width: 80%;
  }

  &__header {
    display: block;
    width: 100%;
  }

  &__ticket {
    position: absolute;
    width: 100%;
    z-index: 0;

    &__body {
      align-items: center;
      display: flex;
    }
  }

  &__text {
    color: $white;
    font-family: 'OpenSans-Bold';
    font-size: 18px;
    padding: 0 30px;
    position: absolute;
    text-align: center;
    z-index: 1;

    p {
      padding: 0 50px;
    }

    a {
      border-bottom: 1px solid $white;
      color: $white;
      display: inline-block;
      margin-left: 5px;
      text-decoration: none;
    }
  }

  &__box {
    margin: 0 40px;
  }

  &__button {
    background: $beige;
    border: 1px solid $beige;
    color: $maroon;
    cursor: pointer;
    font-family: 'Tungsten-Medium';
    font-size: 26px;
    letter-spacing: 1px;
    margin: 10px auto;
    padding: 20px;
    text-align: center;
    text-transform: uppercase;
    transition: all .25s;
    width: 250px;

    &:hover {
      background: $maroon;
      color: $beige;
    }
  }

  &__reset {
    // background: $black;
    color: $white;
    cursor: pointer;
    margin: 20px auto 0;
    padding: 15px 0;
    text-align: center;
    width: 200px;
  }

  &__modal {
    background: $modal-opacity;
    height: 100%;
    position: absolute;
    top: 0;
    width: $container-width;
    z-index: 20;

    &__body {
      background: $white;
      border-radius: 10px;
      margin: 20% 30px 0;
      padding: 20px;
      text-align: center;

      h3 {
        color: $orange;
        font-family: 'OpenSans-Bold';
        font-size: 28px;
        margin-bottom: 10px;
      }

      p {
        font-size: 18px;
        margin: 0 60px 20px;
      }
    }

    &__img {
      margin-top: 30px;
      width: 35%;
    }

    &__close {
      background: $maroon;
      color: $white;
      cursor: pointer;
      font-family: 'Tungsten-Medium';
      font-size: 26px;
      margin: 0 auto 30px;
      padding: 15px;
      text-transform: uppercase;
      width: 300px;
    }
  }
}

#canvas-confetti {
  z-index: 30;
}

.sc {
  &__wrapper {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  &__container {
    margin-top: 180px;
    overflow: hidden;
    position: relative;
    text-align: center;

    &__under {
      align-items: center;
      background-size: cover;
      border-radius: 15px;
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0;

      h2 {
        font-family: 'PermanentMarker';
        font-size: 34px;
        margin: 0;
      }
    }

    & > img {
      border-radius: 15px;
      width: 79%;
    }

    canvas {
      border-radius: 15px;
      height: auto;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__infos {
    height: 40px;
    line-height: 40px;
    margin-top: 5px;
    text-align: center;
  }
}

@media (max-width: 660px) {
  .scratch {
    padding: 0;

    &__prize {
      padding: 0;
    }
  }
}

@media (max-width: 600px) {
  .scratch {
    &__text {
      font-size: 16px;
    }

    &__modal {
      width: 100%;
    }
  }

  .sc {
    &__container {
      margin-top: 155px;
    }
  }
}

@media (max-width: 560px) {
  .scratch {
    // &__ticket {
    //   background-position: center top;
    //   background-size: cover;
    // }

    &__form {
      top: 70px;

      &__container {
        height: 480px;
      }

      &__copy {
        .reg {
          &__bold {
            font-size: 26px;
          }

          &__norm {
            font-size: 18px;
          }
        }
      }
    }

    &__text {
      font-size: 14px;
      padding-bottom: 20px;
    }
  }

  .sc {
    &__container {
      margin-top: 140px;
    }
  }
}

@media (max-width: 500px) {
  .scratch {
    &__form {
      top: 50px;

      &__underline {
        margin-bottom: 50px;
      }
    }

    &__facts {
      font-size: 24px;
    }
  }

  .sc {
    &__container {
      margin-top: 125px;
    }
  }
}

@media (max-width: 476px) {
  .scratch {
    &__body {
      margin: 20px;
    }

    &__modal {
      &__body {
        p {
          font-size: 16px;
          margin: 0 10px 20px;
        }
      }
    }
  }
}

@media (max-width: 456px) {
  .scratch {
    &__text {
      font-size: 13px;
      padding: 0 20px;
    }

    &__form {
      &__container {
        height: 390px;
        margin-top: -90px;
      }

      &__copy {
        margin: 30px auto 20px;

        h2 {
          font-size: 30px;
        }

        .reg {
          &__bold {
            font-size: 24px;
          }
        }
      }

      &__underline {
        margin-bottom: 30px;
      }
    }
  }
}

@media (max-width: 431px) {
  .scratch {
    &__text {
      font-size: 12px;
    }

    &__box {
      padding-top: 30px;
    }

    &__modal {
      &__close {
        width: 100%;
      }
    }
  }

  .sc {
    &__container {
      margin-top: 80px;
    }
  }
}

@media (max-width: 406px) {
  .scratch {
    &__form {
      &__container {
        height: 365px;
        margin-top: -70px;
      }

      &__copy {
        h2 {
          font-size: 26px;
        }

        .reg {
          &__bold {
            font-size: 20px;
          }

          &__norm {
            font-size: 16px;
          }
        }
      }

      &__input {
        font-size: 18px;
      }

      &__submit {
        width: 160px;
      }
    }

    &__facts {
      font-size: 20px;

      &__maroon {
        padding-top: 60px;
      }
    }
  }
}

@media (max-width: 388px) {
  .scratch {
    &__body {
      margin: 10px;
    }
  }
}

@media (max-width: 368px) {
  .scratch {
    &__text {
      font-size: 11px;
    }

    &__modal {
      &__body {
        h3 {
          font-size: 24px;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .sc {
    &__container {
      margin-top: 70px;
    }
  }
}

@media (max-width: 343px) {
  .scratch {
    &__facts {
      font-size: 18px;

      &__maroon {
        padding-top: 50px;
      }
    }

    &__text {
      font-size: 10px;
    }

    &__form {
      &__container {
        height: 320px;
        margin-top: -60px;
      }

      &__copy {
        margin: 20px auto 15px;

        h2 {
          font-size: 24px;
        }
      }
    }
  }
}

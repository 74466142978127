.joinwifi {
  margin: 0 auto;
  max-width: $container-width;

  &__header,
  &__steps {
    display: block;
    width: 100%;
  }

  &__body {
    padding: 40px;
  }

  &__counter {
    align-items: center;
    border: 2px solid $gold;
    color: $maroon;
    display: flex;
    font-family: 'Tungsten-Medium';
    font-size: 40px;
    justify-content: center;
    margin: 0 20px 40px;
    padding: 15px;
    text-align: center;
    text-transform: uppercase;

    span {
      display: inline-block;
    }

    &__number {
      font-family: 'Pride';
      font-size: 48px;
      margin-bottom: -5px;
      margin-right: 5px;
    }
  }
}

@media (max-width: 460px) {
  .joinwifi {
    &__counter {
      font-size: 32px;

      &__number {
        font-size: 40px;
      }
    }
  }
}

@media (max-width: 380px) {
  .joinwifi {
    &__counter {
      font-size: 28px;

      &__number {
        font-size: 32px;
      }
    }
  }
}

.hero,
.landing-hero {
  display: block;
  text-align: center;

  &__sport {
    color: white;
    font-size: 14px;
    z-index: 3;
    position: absolute;
    width: 100%;
    bottom: 15px;
  }

  &__icon {
    color: white;
    z-index: 3;
    position: absolute;
    width: 100%;
    top: 15px;

    img {
      animation: rotation 2s infinite linear;
      display: block;
      max-width: 15px;
      margin: 0 auto;
    }
  }

  &__image {
    display: block;
    width: $container-width;
    z-index: -1;

    &--stats {
      padding: 30px 60px;
      width: 75%;
    }
  }

  &__clock {
    align-items: center;
    color: $white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 211px;
    justify-content: space-around;
    padding: 0 20%;
    position: absolute;
    width: 100%;
    z-index: 3;

    &__score {
      font-family: 'Pride';
      font-size: 48px;
    }

    &__ball {
      display: inline-block;
      padding-right: 5px;
      transform: rotate(90deg);

      &.arrow {
        transform: rotate(0deg);
      }

      &.base {
        font-size: 16px;
        transform: rotate(0deg);
      }
    }

    &__team {
      font-size: 20px;
      text-transform: uppercase;
    }

    &__middle {
      font-size: 24px;
    }

    &__shot {
      font-size:  16px;
    }

    &__inning {
      font-family: 'Pride';
      font-size: 28px;

      p {
        font-family: 'SourceSansPro-Regular';
        font-size: 16px;
        margin: 0;
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.landing-hero {
  height: 212px;
  position: relative;

  // &.volleyball {
  //   .hero {
  //     &__clock {
  //       align-items: end;
  //       grid-template-columns: 20% 55% 20%;
  //       padding: 0;
  //       top: -45px;

  //       &__score {
  //         font-size: 38px;
  //       }

  //       &__team {
  //         display: none;
  //       }
  //     }
  //   }
  // }

  .track {
    &__meet-title {
      background: $maroon-light;
      color: $white;
      font-size: 20px;
      padding: 10px 10px 15px;
      position: absolute;
      width: 100%;
      z-index: 10;
    }

    &__tables {
      color: $white;
      display: flex;
      margin-top: 50px;
      position: absolute;
      width: 100%;
      z-index: 10;

      &__side {
        width: 50%;
      }
    }
  }

  &.baseball {
    .hero__clock {
      margin-top: -40px;
    }
  }

  &__image {
    display: block;
    position: absolute;
    width: $container-width;
    z-index: 0;
  }

  &__sport {
    bottom: 25px;
    color: $white;
    font-size: 18px;
    position: absolute;
    width: 100%;
    z-index: 4;

    img {
      height: 20px;
      margin-bottom: -5px;
      margin-right: 10px;
    }
  }

  &__baseball {
    bottom: 18px;
    color: $white;
    display: flex;
    font-size: 18px;
    justify-content: space-around;
    position: absolute;
    width: 100%;
    z-index: 4;

    &__sport {
      text-transform: uppercase;

      svg {
        margin-right: 5px;
      }
    }

    &__stat {
      align-items: center;
      display: flex;
    }

    &__dots {
      display: flex;
      justify-content: space-around;
      margin-left: 5px;
      width: 30px;

      span {
        background: darken($white, 50%);
        border-radius: 50%;
        display: block;
        height: 5px;
        width: 5px;

        &.active {
          background: $white;
        }
      }
    }
  }
}

.volleyball {
  &__linescore {
    table {
      border-collapse: collapse;
      width: 100%;

      thead {
        tr {
          td {
            border-bottom: 2px solid $maroon;
            color: $maroon;
            font-weight: bold;
            padding: 3px 0;
          }
        }
      }

      tbody {
        tr {
          td {
            border-bottom: 1px solid darken($grey, 10%);
            // color: $white;
            padding: 3px 0;
          }
        }
      }
    }

    &__left {
      text-align: left;
      padding-left: 15px !important;
    }
  }
}

.preview {
  .hero {
    position: relative;

    &__image {
      position: relative;
    }

    &__teams {
      bottom: 40px;
      left: 0;
      margin: 0 auto;
      padding: 0 40px;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }
}

@media (max-width: 600px) {
  .hero {
    &__image {
      width: 100%;
    }
  }

  .landing-hero {
    &__sport {
      bottom: 20px;
      font-size: 14px;

      img {
        height: 15px;
        margin-bottom: -2px;
      }
    }

    &__image {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .landing-hero {
    &.baseball {
      .hero__clock {
        margin-top: -30px;
      }
    }

    &__baseball {
      bottom: 14px;
      font-size: 15px;
    }
  }
}

@media (max-width: 480px) {
  .landing-hero {
    &__sport {
      bottom: 13px;
      font-size: 13px;
    }
  }
}

@media (max-width: 420px) {
  .hero {
    &__sport {
      font-size: 12px;
    }

    &__clock {
      &__score {
        font-size: 36px;
      }

      &__team {
        font-size: 16px;
      }

      &__middle {
        font-size: 20px;
      }
    }
  }

  .landing-hero {
    &__sport {
      bottom: 10px;
      font-size: 12px;

      img {
        height: 13px;
      }
    }

    &.baseball {
      .hero {
        &__clock {
          margin-top: -25px;

          &__score {
            font-size: 30px;
          }

          &__inning {
            font-size: 24px;

            p {
              font-size: 14px;
            }
          }
        }
      }
    }

    &__baseball {
      bottom: 12px;
      font-size: 13px;
    }
  }
}

@media (max-width: 380px) {
  .landing-hero {
    &__baseball {
      font-size: 11px;
    }
  }
}

@media (max-width: 360px) {
  .landing-hero {
    &__sport {
      bottom: 11px;
      font-size: 11px;
    }

    &.baseball {
      .hero {
        &__clock {
          margin-top: -20px;

          &__score {
            font-size: 26px;
          }

          &__inning {
            font-size: 20px;

            p {
              font-size: 12px;
            }
          }
        }
      }
    }

    &__baseball {
      bottom: 9px;
      font-size: 10px;
    }
  }
}

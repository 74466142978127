.home {
  // background: lighten($gold, 10%);
  background: url('/img/bg.png');
  background-size: cover;
  display: block;
  margin: 0 auto;
  max-width: $container-width;
  min-height: 100vh;
  padding-bottom: 40px;
  text-align: center;

  &__sponsors {
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr min-content;
    justify-content: space-around;
    padding: 10px;

    &__full-width {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr min-content;
      padding: 0 10px;
    }
  }
}

.small-hero {
  align-items: center;
  background: $white;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: $container-width;

  &__title {
    color: $gold;
    display: inline-block;
    font-family: 'SourceSansPro-Bold';
    padding-left: 20px;
    text-transform: uppercase;
  }

  &__sponsor {
    display: inline-block;
    float: left;
    height: 40px;
    padding-right: 20px;
  }
}

@media (max-width: 600px) {
  .small-hero {
    width: 100%;

    &__title {
      font-size: 16px;
    }

    &__sponsor {
      height: 25px;
    }
  }
}

// sass-lint:disable no-vendor-prefixes
.promotions {
  margin: 0 auto;
  max-width: $container-width;

  &__header {
    width: 100%;
  }

  &__callout {
    font-size: 24px;
    font-weight: bold;
    padding: 0 2em 8px;
    text-align: center;

    h2 {
      font-family: 'OpenSans-Bold';
      margin-bottom: 5px;
    }

    p {
      font-size: 18px;
      margin: 0;
    }
  }

  &__rules-link {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;

    a {
      border-bottom: 1px solid;
      color: $dark-gray;
    }
  }

  &__form {
    padding: 0 40px 40px;
    text-align: center;

    input {
      &::-webkit-input-placeholder {
        color: lighten($black, 50%);
      }
    }

    &__input {
      -webkit-appearance: none;
      border: 1px solid $dark-gray;
      border-radius: 0;
      font-size: 18px;
      margin-bottom: 10px;
      padding: 15px;
      text-align: center;
      width: 100%;
    }
  }
}

.hot-dogs {
  margin: 0 auto;
  max-width: $container-width;

  &__text {
    color: $maroon;
    font-family: 'SourceSansPro-Bold';
    font-size: 26px;
    margin-top: 20px;
    text-align: center;
  }

  &__table {
    border: 3px solid $maroon;
    border-spacing: 0;
    font-size: 18px;
    margin: 20px auto;

    tr {
      th {
        background: $maroon;
        color: $white;
        font-family: 'SourceSansPro-Bold';
        padding: 5px 10px;
      }

      &:nth-child(even) {
        background: darken($beige, 5%);
      }

      td {
        padding: 8px 13px;

        span {
          display: block;
        }

        &:first-child {
          border-right: 3px solid $maroon;
          padding: 8px 13px;
          text-align: center;
        }
      }
    }
  }
}

.rules {
  margin: 0 auto;
  max-width: $container-width;

  h2 {
    padding-left: 20px;
  }

  &__body {
    padding: 0 20px 40px;

    ol {
      padding-left: 20px;
    }

    p {
      margin: 5px 0;

      strong {
        font-family: 'SourceSansPro-Bold';
      }
    }
  }
}

.confirmation {
  margin: 0 auto;
  max-width: $container-width;
  text-align: center;

  h2 {
    color: $maroon;
    font-family: 'SourceSansPro-Bold';
    font-size: 36px;
    margin-bottom: 10px;
    margin-top: 20px;
    text-transform: uppercase;
  }

  p {
    color: $gold;
    font-family: 'SourceSansPro-Bold';
    margin: 0 0 20px;
    padding: 0 20px;
  }

  &__link {
    display: block;
    padding-bottom: 60px;
  }

  &__image {
    width: 100%;
  }

  &__button {
    margin: 20px 0 40px;

    a {
      display: inline-block;
      width: 100%;
    }

    button {
      font-weight: 300;
      width: 70%;
    }
  }
}

@media (max-width: 555px) {
  .promotions {
    &__callout {
      padding: 0 1em 8px;

      h2 {
        font-size: 28px;
      }
    }
  }
}

@media (max-width: 450px) {
  .promotions {
    &__callout {
      font-size: 18px;
    }

    &__rules-link {
      font-size: 16px;
    }

    &__form {
      padding: 0 30px 40px;

      &__input {
        font-size: 16px;
        padding: 10px;
      }
    }
  }

  .confirmation {
    h2 {
      font-size: 28px;
    }

    p {
      font-size: 14px;
    }

    &__button {
      button {
        width: 85%;
      }
    }
  }
}

@media (max-width: 420px) {
  .promotions {
    &__callout {
      h2 {
        font-size: 24px;
      }
    }
  }
}

@media (max-width: 375px) {
  .promotions {
    &__callout {
      p {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 345px) {
  .promotions {
    &__callout {
      h2 {
        font-size: 22px;
      }
    }
  }
}
